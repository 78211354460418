<template>
  <div>
    <div class="user_info pa-3 pb-1">
        <div class="user_info_left">
          <div class="d-flex align-end">
            <h3>{{ $parent.user.displayName }}</h3>
            <span class="pl-1">{{ $parent.roleText[$parent.user.role] }}</span>
          </div>
          <div><strong>Город:</strong> {{ $parent.user.location }}</div>
          <div><strong>Email:</strong> {{ $parent.user.email  }}</div>
        </div>
        <div class="user_info_right">
          <div class="user_info_right_credit">
            <div class="user_info_right_credit_text">
              <span class="mr-1">Кредит:</span>
              <span>{{ $parent.user.credit ? $parent.user.credit : 0 }}р.</span>
            </div>
            <div v-if="$parent.currentUser.role === 'admin'" class="user_info_right_credit_btn">
              <v-btn @click="$parent.credit('writeOff')" small text>
                Списать
              </v-btn>
              <v-btn @click="$parent.credit('giveOut')" small text>
                Выдать
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <v-divider />

      <div class="d-flex justify-space-between align-center px-3 py-3">
        <div style="font-size: 1em;">
          Заработал за месяц: <strong :class="{ costRed: $parent.earned < 0 }">{{ $parent.earned }}р.</strong>
          Осталось выплатить: <strong>{{ $parent.leftToPaid }}р.</strong>
        </div>
        <div class="user_selectDataMonth">
          <v-text-field
            class="ma-0 pa-0"
            color="rgb(224, 166, 119)"
            v-model="$parent.filterUserDataByMonthText"
            @click="$parent.selectMonth()"
            placeholder="Месяц"
            dense
          />
        </div>
      </div>

      <v-divider />
  </div>
</template>

<script>
export default {}
</script>
